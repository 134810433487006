import "../../styles/global.scss";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MaterialIcon, { colorPalette } from "material-icons-react";
import { setUser } from "../../store/user";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { getParameterByName, serverUrl } from "../utility";
import { useNavigate } from "react-router-dom";
import CourseWrapper from "../../components/course/wrapper";
import styles from "./courseHome.module.scss";
import qualityImg from "../../images/quality.png";

// Render server side and embed as a <WebView />
// https://www.react-google-charts.com/components/chart
function CourseHome() {
  const user = useSelector((state) => state.userStore.user);
  const isStaffUser = useSelector((state) => state.userStore.isStaffUser);
  const organisation = useSelector((state) => state.organisationStore.organisation);
  const sections = useSelector((state) => state.courseStore.sections);
  const [currentStrategy, setCurrentStrategy] = useState(null);
  const navigate = useNavigate();
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    setShowVideo(true);
  }, []);

  const replaceChildName = (content) => {
    const regex = /{{name}}/gi;
    return content.replace(regex, organisation?.name);
  };

  useEffect(() => {
    if (sections && !currentStrategy) {
      const sectionsOrdered = [...sections].sort((a, b) => {
        return a.order < b.order ? -1 : 1;
      });

      const nextSection = sectionsOrdered.find((section) => {
        return section?.courseStatus?.completed !== true;
      });

      if (!nextSection) {
        console.error(`No next section..`);
        return false;
      }

      const nextStrategy =
        nextSection?.courseStatus?.length > 0
          ? nextSection?.courseStatus.find((strategy) => {
              return strategy?.strategyProgress?.completed !== true;
            })
          : nextSection?.speechStrategy[0];

      console.log(`Next strategy is: ${JSON.stringify(nextStrategy)}`);
      const nextStrategyNew = { ...nextStrategy, content: replaceChildName(nextStrategy?.content) };
      setCurrentStrategy(nextStrategyNew);
    }
  }, [sections, currentStrategy]);

  const isStrategyCompleted = useCallback((section, strategyId) => {
    const strategyProgressList = section?.courseStatus?.strategyProgress;

    if (!strategyProgressList) {
      return false;
    }

    const strategyProgressItem = strategyProgressList.find((strategyProgressItem) => {
      return strategyProgressItem?.speechStrategyId === strategyId;
    });

    if (strategyProgressItem) {
      return strategyProgressItem?.completed;
    } else {
      return false;
    }
  }, []);

  const getSectionList = useCallback(
    (section) => {
      return (
        <div>
          {section?.speechStrategy?.map((strategy, strategyIndex) => {
            return (
              <div
                key={`${section?._id}_${strategy?._id}`}
                className={styles.courseSectionStrategy}
                onClick={() => {
                  // TODO: Assumes video
                  console.log(`Setting strategy to: ${strategy?.title}`);
                  const strategyNew = { ...strategy, content: replaceChildName(strategy?.content) };
                  setCurrentStrategy(strategyNew);
                }}
              >
                <div className={styles.strategyTitle}>
                  <div>{strategy?.title}</div>

                  {isStrategyCompleted(section, strategy?._id) &&
                  currentStrategy &&
                  currentStrategy?._id !== strategy?._id ? (
                    <div title={`You finished watching this strategy`}>
                      <MaterialIcon icon="check" size={16} color={"#aeaeae"} />
                    </div>
                  ) : null}

                  {currentStrategy && currentStrategy?._id === strategy?._id ? (
                    <div title={`Current strategy`}>
                      <MaterialIcon icon="circle" size={16} color={"#7cea9c"} />
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      );
    },
    [currentStrategy, setCurrentStrategy]
  );

  return (
    <CourseWrapper isStaffUser={isStaffUser}>
      <Row className={""}>
        <Col xs={12} lg={4} xl={3} className={styles.leftCourseBar}>
          {sections?.length > 0 &&
            sections.map((section, sectionIndex) => {
              return (
                <div key={section?.id} className={styles.courseSection}>
                  <div className={styles.courseTitle}>
                    <div>
                      {sectionIndex + 1}. {section?.title}
                    </div>

                    {section?.courseStatus?.completed ? (
                      <div
                        style={{ position: "absolute", top: 4, right: 10, zIndex: 5 }}
                        title={`You have completed this section`}
                      >
                        <img src={qualityImg} style={{ height: 24 }} />
                      </div>
                    ) : null}
                  </div>

                  {getSectionList(section)}
                </div>
              );
            })}
        </Col>

        <Col lg={4} xl={5} className={styles.courseContentMeta}>
          <h2 className={"h4"}>{currentStrategy?.title}</h2>
          <p>{currentStrategy?.content}</p>
        </Col>

        <Col lg={4} xl={4} className={styles.courseContentArea}>
          {currentStrategy && currentStrategy.videoURL && showVideo ? (
            <video
              className={styles.courseVideo}
              controls
              key={currentStrategy.videoURL}
              autoPlay={true}
            >
              <source src={currentStrategy.videoURL} type="video/mp4"></source>
            </video>
          ) : null}
        </Col>
      </Row>
    </CourseWrapper>
  );
}

export default CourseHome;
